import React, { createRef, useEffect, useRef, useState } from "react"
import {
  Nav,
  NavItem,
  Modal,
  NavLink,
  TabContent,
  TabPane,
  Button,
  FormGroup,
  ModalBody,
  Input,
  Label,
  UncontrolledDropdown,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import axios from "axios"
import { useHistory } from "react-router-dom"
import RDZero from "./RD-0"
import ProjectHeader from "../ProjectHeader"
import UnAuthorizedComponent from "common/UnAuthorizedComponent"
import { convertPermission } from "permissionUtils"

const ACCESS_DENIED_MESSAGE = "Access denied!"
const STAGE_ID = "10"
const API_URL = "https://rd0.cpvarabia.com/api/Sections/View"

const getFormData = projectId => ({
  PerToken: localStorage.getItem("token"),
  PerUserID: localStorage.getItem("id"),
  PerRoleID: localStorage.getItem("userRoleID"),
  StageID: STAGE_ID,
  ProjectID: projectId,
})

const fetchData = async formData => {
  try {
    const res = await axios.post(API_URL, formData)

    let array = Object.entries(res.data)
    array.splice(-1)
    return array
  } catch (error) {
    if (res.data.error === true && res.data.message === ACCESS_DENIED_MESSAGE) {
      setAuth(true)
      setTimeout(() => {
        history.push("/logout")
        setTimeout(() => {
          history.push("/login")
        }, 1000)
      }, 4000)
    }
  }
}

const PreviousButton = ({ onClick }) => (
  <Button onClick={onClick} className="bg-primary me-2">
    {"< previous"}
  </Button>
)

const SubmitButton = ({ onClick, children }) => (
  <Button type="submit" className="bg-primary me-2" onClick={onClick}>
    {children}
  </Button>
)

const AnswerPage = props => {
  const history = useHistory()
  const projectId = props.match.params.id
  const { state } = props.location
  const SpecialSystem = state
    ? state.SpecialSystem
    : JSON.parse(localStorage.getItem("SpecialSystem"))
  const project = state
    ? state.project
    : JSON.parse(localStorage.getItem("project"))
  const userPermissions = convertPermission(
    JSON.parse(localStorage.getItem("roles"))
  )

  const [auth, setAuth] = useState(false)
  const [allValues, setAllValues] = useState([])
  const [activeTab, setActiveTab] = useState("1")
  const [data, setData] = useState([])
  const [submittedReports, setSubmittedReports] = useState([])

  useEffect(() => {
    const formData = getFormData(projectId)
    fetchData(formData).then(data => setData(data))
  }, [])

  const isLastTab = activeTab === data.length.toString()
  const canGoBack = activeTab > 1
  const decrementActiveTab = () => {
    if (canGoBack) {
      setActiveTab((activeTab - 1).toString())
    }
  }

  const formRef = useRef()
  const formRefs = useRef([])
  formRefs.current = data.map((item, i) => formRefs.current[i] ?? createRef())

  if (auth) {
    return <UnAuthorizedComponent />
  }

  const TAB_WIDTH = "80%"

  const [reasonModal, setreasonModal] = useState(false)
  const [outofRD0Reason, setOutofRD0Reason] = useState({ outofRD0Reason: "" })
  const reasonToggle = () => {
    setreasonModal(!reasonModal)
  }
  const confirmFunc = e => {
    e.preventDefault()
    setAllValues({
      ...allValues,
      outofRD0Reason: outofRD0Reason["outofRD0Reason"],
    })

    formRefs.current[activeTab - 1]?.current?.props?.onSubmit()
    // reasonToggle()   
  }
  // console.log("allvalues", allValues)

  return (
    <div className="page-content">
      <ProjectHeader projectID={project.ProjectID} from={"RD0"}/>

      <h4 className="mb-3">RD-0 Report Answer</h4>
      <Nav tabs fill style={{ width: TAB_WIDTH }}>
        {data.map((item, index) => (
          <NavItem key={item[1].SectionID}>
            <NavLink
              className={activeTab === (index + 1).toString() ? "active" : ""}
              onClick={() => setActiveTab((index + 1).toString())}
            >
              {item[1].SectionName}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {data.map((item, index) => (
          <TabPane tabId={(index + 1).toString()} key={item[1].SectionID}>
            <RDZero
              id={item[1].SectionID}
              projectId={projectId}
              submittedReports={submittedReports}
              setSubmittedReports={setSubmittedReports}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              submittedKey={index + 1}
              tabsLength={data.length}
              allValues={allValues}
              setAllValues={setAllValues}
              setAuth={setAuth}
              SpecialSystem={SpecialSystem}
              userPermissions={userPermissions}
              refAP={formRefs.current[index]}
              formRef={formRef}
            />
          </TabPane>
        ))}
      </TabContent>
      <FormGroup className="d-flex ms-auto col-3 mt-5 justify-content-around">
        {isLastTab ? (
          <div>
            <PreviousButton onClick={decrementActiveTab} />
            {/* <SubmitButton onClick={() => reasonToggle()}>
              Out Of RD0
            </SubmitButton>
            <SubmitButton
              onClick={() =>
                formRefs.current[activeTab - 1]?.current?.props?.onSubmit()
              }
            >
              Submit
            </SubmitButton> */}
            <UncontrolledDropdown className="btn-group bg-primary">
              <SubmitButton
                onClick={() =>
                  formRefs.current[activeTab - 1]?.current?.props?.onSubmit()
                }
              >
                Submit
              </SubmitButton>
              <DropdownToggle
                tag="a"
                to="#"
                className="card-drop"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="mdi mdi-dots-horizontal font-size-18 text-light me-1"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <DropdownItem onClick={() => reasonToggle()}>
                  Out Of RD APP
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        ) : (
          <div>
            {canGoBack && <PreviousButton onClick={decrementActiveTab} />}
            <SubmitButton
              onClick={() =>
                formRefs.current[activeTab - 1]?.current?.props?.onSubmit()
              }
            >
              {"Next >"}
            </SubmitButton>
          </div>
        )}
      </FormGroup>
      <Modal isOpen={reasonModal} toggle={reasonToggle}>
        <div className="modal-content">
          <ModalBody className="px-4 py-5 ">
            <button
              type="button"
              className="btn-close position-absolute end-0 top-0 m-3"
            ></button>
            <form>
              <div>
                <Label>Reason :</Label>
                <Input
                  type="textarea"
                  onChange={e => {
                    outofRD0Reason["outofRD0Reason"] = e.target.value
                  }}
                  required
                />
              </div>
              {!outofRD0Reason["outofRD0Reason"] ? (
                <div>
                  <span className="text-danger">Required</span>
                </div>
              ) : null}
              <div className="d-flex ms-auto col-5 mt-3 justify-content-around">
                <button
                  onClick={() => {
                    reasonToggle()
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={e => {
                    outofRD0Reason["outofRD0Reason"] && confirmFunc(e)
                  }}
                >
                  Confirm
                </button>
              </div>
            </form>
          </ModalBody>
        </div>
      </Modal>
    </div>
  )
}

export default React.memo(AnswerPage)
